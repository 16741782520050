.userModal__permissionsContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.userModal__checkboxContainer {
  box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
  outline-color: var(--secondaryColor);
  outline-offset: 0;
  border: 2px solid var(--primaryColor--light);
  padding: calc(2 * var(--gutter));
}

.checkboxList__columnWrapper {
  padding: var(--gutter) 0;
}
